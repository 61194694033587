import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility Class will be today from 12:00-12:30.`}</em></strong></p>
    <p>{`Warmup: Band OHS & Lateral Band Walks 3×10`}</p>
    <p>{`Overhead Squats 4×8\\@75%`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time of:`}</p>
    <p>{`45-Double Unders`}</p>
    <p>{`30-Squats`}</p>
    <p>{`15-Power Snatch (95/65)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Exciting news!  Work on our back lot will start tomorrow so you
will not be able to use the Washington Street entrance.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Reminder:  Evening CrossFit classes are now offered Monday-Friday
at 4:00, 5:00 & 6:00pm. and o**`}</strong>{`ur new Ville Bootcamp classes are
Tues-Friday at 9:30am.  The rest of this month the bootcamps are free
for non members so spread the word if you know of anyone that may want
to try it out. This class is included in your unlimited membership. ***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      